import React from 'react';

import { Layout } from '../../components/common';
import SEO from '../../components/common/SEO';
import { useViewport } from '../../hooks';
import DesktopEventPage from '../../components/common/DesktopEventPage';
import MobileEventPage from '../../components/common/MobileEventPage';

import banner from '../../images/events/2025/mhv/MHV_events-desktop-hero.jpg';
import mobileBanner from '../../images/events/2025/mhv/MHV_events-mobile-hero.jpg';
import ogimage from '../../images/events/2025/mhv/MHV_events-og-image.jpg';

const ACMMileHigh2025 = () => {
    const { width } = useViewport();
    const breakpoint = 500;

    return (
        <Layout title='ACM Mile High Video 2025 - Witbe' isEvents>
            <SEO
                title='ACM Mile High Video 2025 - Witbe'
                ogDescription='Mathieu Planche, Witbe’s CEO will be presenting at ACM Mile-High Video 2025 - a leading video coding and streaming event.'
                description='Mathieu Planche, Witbe’s CEO will be presenting at ACM Mile-High Video 2025 - a leading video coding and streaming event.'
                image={ogimage}
            />

            {width < breakpoint ? <MobileEventPage id={15} banner={mobileBanner} /> : <DesktopEventPage id={15} banner={banner} />}
        </Layout>
    );
};

export default ACMMileHigh2025;
